import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForwardSharp } from "react-icons/io5";
import Slider from 'react-slick';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure the CSS is imported
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BusBookingDiscountOffers = (props) => {
    const offers = props.offer
    const arrowNext = process.env.PUBLIC_URL + 'assets/images/arrow-next.png';
    const arrowPrev = process.env.PUBLIC_URL + 'assets/images/arrow-prev.png';
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        const [isHovered, setIsHovered] = useState(false);
        return (
            <IoChevronForwardSharp
                className={className}
                style={{
                    ...style, display: 'block', color: 'black', border: '1px solid #3333332d', padding: '7px', borderRadius: '50%', height: '40px', width: '40px',
                    backgroundColor: isHovered ? 'skyblue' : 'white',
                    color: isHovered ? 'white' : 'black',
                }}
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />

        );
    };

    // const CustomPrevArrow = (props) => {
    //     const { className, style, onClick } = props;
    //     const [isHovered, setIsHovered] = useState(false);

    //     return (
    //         <IoChevronBack
    //             className={className}
    //             style={{
    //                 ...style, display: 'block', color: 'black', border: '1px solid #3333332d', padding: '7px', borderRadius: '50%', height: '40px', width: '40px',
    //                 backgroundColor: isHovered ? 'skyblue' : 'white',
    //                 color: isHovered ? 'white' : 'black',
    //             }}
    //             onClick={onClick}
    //             onMouseEnter={() => setIsHovered(true)}
    //             onMouseLeave={() => setIsHovered(false)}
    //         />
    //     );
    // };

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: " 0 5px 8px #00000038",
                    borderRadius: '50%',
                    padding: '21px',
                    right: '10px',
                    margin:'-1px 2px',
                    zIndex: 1,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
                onClick={onClick}
                onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = " 0 5px 8px #00000038";
                    e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = " 0 5px 8px #00000038";
                    e.currentTarget.style.transform = 'translateY(-50%)';
                }}
            >
                <img src={arrowPrev} alt="next icon" class="img-fluid" />
            </div>
        );
    };
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: " 0 5px 8px #00000038",
                    borderRadius: '50%',
                    margin:'-1px -36px',
                    padding: '21px',
                    right: '10px',
                    zIndex: 1,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    transition: 'background 0.3s ease, transform 0.3s ease',
                }}
                onClick={onClick}
                onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 5px 8px #00000038";
                    e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "0 5px 8px #00000038";
                    e.currentTarget.style.transform = 'translateY(-50%)';
                }}
            >
                <img src={arrowNext} width="90px" alt="bus icon" class="img-fluid" />

            </div>
        );
    };
    const sliderSettings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>

            <section className="mt-100 bookdiscoubnt--section">
                <div className="bookdiscoubntslider--div">
                    <div className="swiper bookdiscoubnt_slider">
                        <div className="container">
                            <div className="titlediv d-flex justify-content-center position-relative align-items-center mb-3 mb-lg-5">
                                <h4>Bus Booking Discount Offers</h4>
                            </div>
                        </div>
                        <Slider {...sliderSettings} className="px-6">
                            {offers?.banner?.map((dis, index) => (
                                <div key={index} className="px-4">
                                    <div className="d-flex flex-column align-items-center justify-content-center transform hover-scale transition duration-300">
                                        <img
                                            src={offers?.image_url + dis?.image}
                                            alt=""
                                            // className="img-fluid"
                                            style={{ width: '90%', height: '90%' }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
            <style jsx>{`
                .bookdiscoubnt--section {
                    overflow-x: hidden;
                }
                .bookdiscoubntslider--div {
                    width: 100%;
                    overflow: hidden;
                }
                .swiper.bookdiscoubnt_slider {
                    padding: 0 50px;
                    box-sizing: border-box;
                }
            `}</style>
        </div>
    );
};

export default BusBookingDiscountOffers;
