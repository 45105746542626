import { useEffect, useState } from "react";
import { FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Button } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header from "../components/header";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const LoginDetail = () => {
    const location = useLocation();
    const [mobileNo, setMobileNo] = useState()
    const [password, setPassword] = useState()
    const handleMouseDownPassword = (event) => event.preventDefault();
    const [showPasswordIcon, setShowPasswordIcon] = useState(false)
    const [errors, setErrors] = useState({});
    const [loginData, setLoginData] = useState([]);
    const [otpAPI, setOtpAPI] = useState()
    const handleClickPassword = () => setShowPasswordIcon((show) => !show);
    const history = useHistory()
    const [otp, setOtp] = useState();
    const [showOtpBox, setShowOtpBox] = useState(false)
    const [loginBox, setLoginBox] = useState(true)
    const [timer, setTimer] = useState(30);
    const [resendEnabled, setResendEnabled] = useState(false);
    // eslint-disable-next-line no-use-before-define
    const { booking } = location.state || { booking: null };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setResendEnabled(true);
        }
        console.log('busid', booking);

    }, [timer]);

    const handleResendOtp = () => {
        setTimer(30);
        setResendEnabled(false);
        handelLoginOTP();
    };


    const handleOtp = () => {
        const newErrors = {};
        if (!otp) {
            newErrors.otp = 'please enter Otp';
            toast.error('Please enter Otp');
        }
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            console.log('add droppoint')
            if (otp == otpAPI) {
                LoginAPI();
            } else {
                toast.error('Invalid OTP')
            }
        }
    };

    const LoginAPI = async () => {
        let data = new FormData();
        data.append('number', mobileNo)
        data.append('password', password)
        data.append('otp', otp)
        try {
            await axios.post("customer_login", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    setLoginData(res.data.data)
                    setShowOtpBox(true)
                    setLoginBox(false)
                    toast.success(res.data.message)
                    localStorage.setItem('UserID', res?.data?.user_data?.id)
                    localStorage.setItem('UserName', res?.data?.user_data?.name)
                    localStorage.setItem('EmailID', res?.data?.user_data?.email)
                    localStorage.setItem('MobileNo', res?.data?.user_data?.number)
                    setTimeout(() => {

                        history.push('/')
                    }, 2000);
                } else {
                    toast.error(res.data.message)
                }
            })
        }
        catch (error) {
            toast.error(error.data.message || 'API is not working');
        }
    }

    const handleLogin = () => {
        const newErrors = {};

        if (!mobileNo) {
            newErrors.mobileNo = 'mobile No is required';
            toast.error('Mobile Number is required');
        }
        if (!password) {
            newErrors.password = 'password is required';
            toast.error('Password is required');
        }
        else if (!/^\d{10}$/.test(mobileNo)) {
            newErrors.mobileNo = 'mobile number must be 10 digits';
            toast.error('Mobile number must be 10 digits');
        }
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            console.log('add')
            handelLoginOTP();
        }
    };

    const handelLoginOTP = async () => {
        let data = new FormData();
        data.append('number', mobileNo)
        data.append('password', password)
        try {
            await axios.post("customer_login", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    setLoginData(res.data.data)
                    toast.success(res.data.message);
                    localStorage.setItem('UserID', res?.data?.user_data?.id)
                    localStorage.setItem('UserName', res?.data?.user_data?.name)
                    localStorage.setItem('EmailID', res?.data?.user_data?.email)
                    localStorage.setItem('MobileNo', res?.data?.user_data?.number)
                    setTimeout(() => {
                        if (booking == 'pendingTicket') {
                            history.goBack();
                        } else {
                            history.push('/')
                        }
                    }, 2000);
                } else {
                    toast.error(res.data.message || 'Invalid Message');
                }
            })
        }
        catch (res) {
            toast.error(res.data.message);
        }
    }

    const handelBackLogin = () => {
        setLoginBox(true);
        setShowOtpBox(false);
    }


    return (
        <>
            <div>
                <Header />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <section className="loginsection py-5">

                    <div className="container">
                        <div className="py-5 hero--content">
                            {loginBox &&
                                <div className="center-container" >
                                    <div className="form-wrapper">
                                        <form >
                                            <div className="my-4">
                                                <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Log in</h5>
                                                <span style={{ color: "#6c2a7f", fontWeight: 500 }}  >Please fill your information below</span>
                                            </div>
                                            <div>
                                                <div className="my-4">
                                                    <OutlinedInput
                                                        type="number"
                                                        value={mobileNo}
                                                        onChange={(e) => setMobileNo(e.target.value)}
                                                        startAdornment={<InputAdornment position="start">+91</InputAdornment>}
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        placeholder="Mobile Number"
                                                    />
                                                </div>
                                                <div className="my-4">
                                                    <FormControl variant="outlined">
                                                        <OutlinedInput
                                                            value={password}
                                                            size="small"
                                                            sx={{ minWidth: 370 }}
                                                            placeholder="Password"
                                                            id="outlined-basic"
                                                            type={showPasswordIcon ? 'text' : 'password'}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPasswordIcon ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                    <div className="d-flex justify-content-end my-2">
                                                        <span style={{ color: "darkblue", cursor: "pointer", fontWeight: 500 }} onClick={() => history.push('/forgot-password')}>Forgot Password?</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <Button variant="contained" onClick={handleLogin}>Login </Button>
                                                    {/* <Button variant="contained" onClick={handleLogin}>Next</Button> */}
                                                </div>
                                                <div className="d-flex justify-content-center mr-2 my-4">
                                                    Don’t have an account?
                                                    <span style={{ color: "darkblue", fontWeight: 500, cursor: "pointer" }} onClick={() => history.push('/sign-up')} > Register yourself</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>}

                            {/* OTP Form */}

                            {/* {showOtpBox &&
                                <div className="center-container">
                                    <div className="form-wrapper">
                                        <form>
                                            <div className="my-4">
                                                <h5 style={{ color: "#6c2a7f", fontWeight: 700 }} className="fs-4 font-weight-bold">Enter OTP</h5>
                                                <span style={{ color: "#6c2a7f", fontWeight: 500 }}>Please enter the OTP sent to your number</span>
                                            </div>
                                            <div>
                                                <div className="mt-4 mb-1">
                                                    <TextField
                                                        required
                                                        type="number"
                                                        id="outlined-number"
                                                        sx={{ minWidth: 370 }}
                                                        size="small"
                                                        value={otp}
                                                        placeholder="Enter otp"
                                                        onChange={(e) => setOtp(e.target.value)}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end mb-4">
                                                    <span
                                                        style={{ color: resendEnabled ? 'blue' : "gray", fontWeight: 500, cursor: "pointer" }}
                                                        onClick={resendEnabled ? handleResendOtp : null}
                                                    >
                                                        {resendEnabled ? 'Re-send otp' : `Re-send otp in ${timer}s`}
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-center gap-4">
                                                    <Button onClick={handelBackLogin}>Back</Button>
                                                    <Button variant="contained" onClick={handleOtp}>Login</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default LoginDetail