import React, { useState, useEffect } from 'react';
import '../styles/style.css';
import { CgProfile } from "react-icons/cg";
import { CiMenuBurger } from "react-icons/ci";
import { Offcanvas, Nav, Navbar, Container, Dropdown, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';

const Header = () => {
    const history = useHistory();
    const logo = process.env.PUBLIC_URL + '../../assets/images/sarthi bus logo.png';
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState(false);


    const handleLogOut = () => {
        localStorage.removeItem('UserName')
        localStorage.removeItem('EmailID')
        localStorage.removeItem('MobileNo')
        localStorage.removeItem('UserID')
        // toast.success('Logut Successfully');
        setOpen(false)
        history.push('/')
        // setTimeout(() => {
        // }, 2000);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleOpenProfile = () => {
        const data = localStorage.getItem('UserID');
        if(data){
            history.push('/profile/edit-profile')
        }else {
            history.push('/profile/support')
        }
    }
    return (
        <>
            {/* <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
            <header className="shadow-sm">
                <Navbar expand="lg" >
                    <Container>
                        <Navbar.Brand >
                            <img src={logo} alt="Logo" className="img-fluid" onClick={() => window.location.href = '/'} style={{ maxHeight: '55px', cursor: "pointer" }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow}>
                            <CiMenuBurger size={25} />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav" className="text-capitalize d-lg-flex fw-medium menu">
                            <Nav className="m-auto mb-2 mb-lg-0">
                                <Nav.Link href="/about-us" >About</Nav.Link>
                                {/* {/ <Nav.Link href="FAQs.php">FAQs</Nav.Link> /} */}
                                <Nav.Link href="/blog-details">Blog</Nav.Link>
                                <Nav.Link href="/contact-us">Contact</Nav.Link>
                            </Nav>
                            <Nav className="list-unstyled m-0">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" className="nav-link dropdown-toggle d-flex align-items-center gap-1">
                                        <CgProfile size={25} /> Account
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={handleOpenProfile}>Profile</Dropdown.Item>
                                        {!localStorage.getItem('UserID') ?
                                            <Dropdown.Item onClick={() => window.location.href = '/login'}>Login</Dropdown.Item> :
                                            <Dropdown.Item onClick={handleClickOpen}>Logout</Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Offcanvas show={show} onHide={handleClose} className="menu--offcanvas text-capitalize fw-semibold p-5">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Menu</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="navbar-nav m-auto mb-2 mb-lg-0">
                            <Nav.Link href="/about-us" >About</Nav.Link>
                            {/* <Nav.Item>
                            <Nav.Link href="FAQs.php">FAQs</Nav.Link>
                        </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link href="/blog-details">Blog</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/contact-us">Contact</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav className="list-unstyled m-0">
                            <Dropdown>
                                <Dropdown.Toggle variant="link" className="nav-link dropdown-toggle d-flex align-items-center gap-1">
                                    <CgProfile size={25} /> Account
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => history.push('/profile')}>Profile</Dropdown.Item>
                                    {!localStorage.getItem('UserID') ?
                                        <Dropdown.Item onClick={() => window.location.href = '/login'}>Login</Dropdown.Item> :
                                        <Dropdown.Item onClick={handleClickOpen}>Logout</Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>

                <Dialog
                    open={open}
                    // TransitionComponent={Transition}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "30%",
                                maxWidth: "375px",  // Set your width here
                            },
                        },
                    }}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{ color: "red" }} className='text-center'>  {"Logout"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" className='text-center'>
                            Are you sure you want to Log Out ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className='text-center justify-content-center'>
                        <Button color="error" onClick={() => setOpen(false)}>Cancel</Button>
                        <Button variant="contained" style={{ color: 'white', backgroundColor: 'red' }} onClick={handleLogOut}>Yes Logout!</Button>
                    </DialogActions>
                </Dialog>
            </header>
        </>
    );
};

export default Header;
