import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const EditProfile = () => {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [userID, setUserID] = useState()
    const [mobileNo, setMobileNo] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState()
    const [showPasswordIcon, setShowPasswordIcon] = useState(false)
    const [errors, setErrors] = useState({});
    const handleClickPassword = () => setShowPasswordIcon((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        setName(localStorage.getItem('UserName'));
        setMobileNo(localStorage.getItem('MobileNo'));
        setEmail(localStorage.getItem('EmailID'))
        setUserID(localStorage.getItem('UserID'))
    }, [])

    const validUpdateDetails = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
            toast.error('Name is required');
        }
        if (!password) {
            newErrors.password = 'Password is required';
            toast.error('Password is required');
        }
        setErrors(newErrors);
        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            updateProfile();
        }
    }
    const updateProfile = async () => {
        let data = new FormData();
        data.append('user_id', userID)
        data.append('name', name)
        data.append('password', password)
        try {
            await axios.post("edit_profile", data, {
            }).then((res) => {
                if (res.data.success == true) {
                    // setLoginData(res.data.data)
                    // setShowOtpBox(true)
                    // setLoginBox(false)
                    localStorage.setItem('UserName', name)
                    toast.success(res.data.message)
                    // setTimeout(() => {

                    //     history.push('/')
                    // }, 2000);
                } else {
                    toast.error(res.data.message)
                }
            })
        } catch (error) {
            toast.error(error.data.message || 'API is not working');
        }
    }

    return (

        <div className="tab-pane active" id="editprofile">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="profile--content--div">
                <div className="prflmindiv">
                    <div className="edtprof--div">
                        <div className="eidtproftitle titlediv my-3">
                            <h5 className="text-capitalize fw-semibold fs-4">edit profile</h5>
                        </div>
                        <div className="profileinfo--collectdiv mt-4">
                            <form className="pasngerdet--colectform">
                                <div className="passengerticktcard">
                                    <div className="psngrdet--1 mt-3">
                                        <div className="roww">
                                            <div className="col-md-9">
                                                <div className="mb-4 form-group">
                                                    <label htmlFor="name" className="form-label fs-6 fw-semibold text-capitalize">Name</label>
                                                    <TextField size='small' className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                                {/* <div className="mb-4 form-group">
                                                    <label htmlFor="dateofbrth" className="form-label fs-6 fw-semibold text-capitalize">Date of birth</label>
                                                    <input type="date" className="form-control" id="dateofbrth" />
                                                </div> */}
                                                {/* <div className="selectgemnddiv form-group mb-4">
                                                    <div className="titlediv">
                                                        <h6 className="fw-semibold fs-6 text-capitalize">select gender</h6>
                                                    </div>
                                                    <div className="d-flex align-items-center gap-5 mt-3">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id="male1" />
                                                            <label className="form-check-label fs-16 fw-medium text-capitalize text-gray" htmlFor="male1">male</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id="female2" />
                                                            <label className="form-check-label fs-16 fw-medium text-capitalize text-gray" htmlFor="female2">female</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="gender" id="other2" />
                                                            <label className="form-check-label fs-16 fw-medium text-capitalize text-gray" htmlFor="other2">other</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="mb-4 form-group">
                                                    <label htmlFor="mobilenumber" className="form-label fs-6 fw-semibold text-capitalize">mobile number</label>
                                                    <TextField size='small' className="form-control" placeholder="Mobile Number" disabled value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />

                                                </div>
                                                <div className="mb-4 form-group">
                                                    <label htmlFor="email" className="form-label fs-6 fw-semibold text-capitalize">email</label>
                                                    <TextField size='small' className="form-control" placeholder="Email" value={email} disabled onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                <div className="mb-4 form-group">
                                                    <label htmlFor="email" className="form-label fs-6 fw-semibold text-capitalize">Password</label>
                                                    <FormControl variant="outlined" className="form-control">
                                                        <OutlinedInput
                                                            value={password}
                                                            size="small"
                                                            sx={{ minWidth: 370 }}
                                                            placeholder="Password"
                                                            id="outlined-basic"
                                                            type={showPasswordIcon ? 'text' : 'password'}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPasswordIcon ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="mb-4 form-group text-end">
                                                    <Button variant='contained' onClick={validUpdateDetails}>Update Profile</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProfile;
