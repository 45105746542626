import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { PartnersSection } from '../components/partner';
import BusBookingDiscountOffers from '../components/offer';
import TicketStepsSection from '../components/ticketStep';
import ChooseUs from '../components/chooseUs';
import AboutUs from '../components/aboutUsHome';
import Blogs from '../components/blogs';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaBusAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Autocomplete, Button, TextField } from '@mui/material';
import axios from 'axios';
import { format, addDays } from 'date-fns';
import Slider from 'react-slick';

import AboutUsHome from '../components/aboutUsHome';
import { toast, ToastContainer } from 'react-toastify';
import { IoChevronBack, IoChevronForwardSharp } from 'react-icons/io5';

const Home = () => {
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [errors, setErrors] = useState({});
    const [cities, setCities] = useState([]);
    const location = useLocation();
    const [bannerMainImg, setBannerMainImg] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedReturnDate, setSelectedReturnDate] = useState(new Date());
    const formattedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '';
    const busIcon = process.env.PUBLIC_URL + 'assets/images/bus-icon.svg';
    const dirIcon = process.env.PUBLIC_URL + 'assets/images/direction.png';
    const bannerImage = process.env.PUBLIC_URL + 'assets/images/banner1.png';
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const swapLocations = () => {
        const temp = from;
        setFrom(to);
        setTo(temp);
    };

    const aboutSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        nextArrow: <IoChevronForwardSharp />,
        prevArrow: <IoChevronBack />,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        nextArrow: <IoChevronForwardSharp />,
        prevArrow: <IoChevronBack />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const handleBusShow = () => {
        const newErrors = {};

        // const userID = localStorage.getItem('UserID');

        // if (!userID) {
        //     newErrors.userID = 'Please Login First';
        //     toast.error('Please Login First');
        //     setTimeout(() => {
        //         history.push('/login');
        //     }, 2000);
        // } else

            if (!from) {
                newErrors.from = 'Please enter the Boarding point';
                toast.error('Please enter the Boarding point');
            } else if (!to) {
                newErrors.to = 'Please enter the Drop Point';
                toast.error('Please enter the Drop Point');
            } else if(from == to  ){
                newErrors.to = 'Please enter different Boarding and Dropping point';
                toast.error('Please enter different Boarding and Dropping point');
            }

        setErrors(newErrors);

        const isValid = Object.keys(newErrors).length === 0;
        if (isValid) {
            history.push({
                pathname: '/bus-list',
                state: { to, from, formattedDate }
            });
        }
    };

    useEffect(() => {
        cityList();
        bannerMainList();
    }, [])

    const cityList = async (searchTerm) => {
        let data = new FormData();
        data.append('search', searchTerm)
        const params = {
            'seach': searchTerm
        }
        try {
            await axios.post("city_list", data, {
                params: params
            }).then((res) => {
                setCities(res.data.data.all_city)

            })
        }
        catch (error) {
            // toast.error(error.data.message);
        }
    }

    const bannerMainList = async () => {
        let data = new FormData();
        try {
            const res = await axios.post("home", data);
            console.log(res.data.data, "banner");
            setBannerMainImg(res.data.data);
        } catch (error) {
            // toast.error(error.response.data.message);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const formattedDate = date ? date.toLocaleDateString('en-GB') : '';
        console.log(formattedDate);
    };
    const handlereturnDateChange = (date) => {
        setSelectedReturnDate(date);
        const formattedDate = date ? date.toLocaleDateString('en-GB') : '';
        console.log(formattedDate);
    };
    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly
        />
    ));
    <style jsx>{`
        .bookdiscoubnt--section {
            overflow-x: hidden;
        }
        .bookdiscoubntslider--div {
            width: 100%;
            overflow: hidden;
        }
        .swiper.bookdiscoubnt_slider {
            padding: 0 100px;
            box-sizing: border-box;
        }
    `}</style>
    return (
        <div>
            <Header />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <section className="herosection">
                <div className="heromain--div py-5">
                    <div className="container">
                        <div className="py-5 hero--content">
                            <div className="hero--contentdiv py-5">
                                <div className="herotitlediv text-center text-capitalize">
                                    <h1 className="fw-bolder fs-1a mb-4">
                                        welcome to <span style={{ color: "#6c2a7f" }}>sarthi bus</span> booking
                                    </h1>
                                    <h5 className="text-gray">
                                        Your journey to seamless and convenient bus travel starts here.
                                    </h5>
                                </div>
                                <div className="runningbus mt-5">
                                    <div className="runningbusanim">
                                        <img src={busIcon} alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="herofrom-to--div p-5 bg-white shadow rounded-4">
                                    <div className="fromtodiv text-capitalize">
                                        <form className="fromtoform">
                                            <div className="mainfromtoform--div">
                                                <div className="align-items-end d-flex dircetions flex-sm-nowrap flex-wrap gap-4 justify-content-center">
                                                    <div className="form-group w-100">
                                                        <label htmlFor="from" className="fw-semibold text-gray fs-5">
                                                            <FaBusAlt />   From City
                                                        </label>
                                                        <Autocomplete
                                                            id="from-city"
                                                            options={cities}
                                                            getOptionLabel={(option) => option.city_name}
                                                            value={from}
                                                            onChange={(event, newValue) => setFrom(newValue)}
                                                            // onInputChange={handleInputChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size="small"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="directimg mb-2 text-dark">
                                                        <img
                                                            src={dirIcon}
                                                            alt=""
                                                            className="img-fluid"
                                                            onClick={swapLocations}
                                                        />
                                                    </div>
                                                    <div className="form-group w-100">
                                                        <label htmlFor="to" className="fw-semibold text-gray fs-5">
                                                            <MdLocationOn />  To City
                                                        </label>

                                                        <Autocomplete
                                                            id="from-city"
                                                            options={cities}
                                                            getOptionLabel={(option) => option.city_name}
                                                            value={to}
                                                            onChange={(event, newValue) => setTo(newValue)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size="small"
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="form-group w-100">
                                                        <label htmlFor="date" className="fw-semibold text-gray mb-2">
                                                            date
                                                        </label>
                                                        <div style={{ borderBottom: "2px solid gray", width: "213px" }}>
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={new Date()}
                                                                customInput={<CustomDateInput />} // Custom input component
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="form-group w-100">
                                                        <label htmlFor="date" className="fw-semibold text-gray mb-2">
                                                            Return Date (Optional)
                                                        </label>
                                                        <div style={{ borderBottom: "2px solid gray", width: "213px" }}>
                                                            <DatePicker
                                                                selected={selectedReturnDate}
                                                                onChange={handlereturnDateChange}
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={new Date()}
                                                                customInput={<CustomDateInput />} // Custom input component


                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="btndivv mt-4 text-center">
                                                        <Button variant="contained" onClick={handleBusShow}>
                                                            Search
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PartnersSection />

            <BusBookingDiscountOffers offer={bannerMainImg} />

            <TicketStepsSection />

            <section className="mt-100 banner--sec">
                <div className="bnner--main">
                    <div>
                        <img src={bannerMainImg?.home_page_full_image} alt="" className="img-fluid w-100" />
                    </div>
                </div>
            </section>
            <ChooseUs />
            {/* <AboutUsHome review={bannerMainImgy} /> */}
            {/* <Blogs user={bannerMainImg} /> */}
            <Footer />
        </div>

    );
};

export default Home;
