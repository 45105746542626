import React from 'react';

const whyUsData = [
    { icon: "assets/icons/path.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/busblue.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/mny.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/tckt.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/path.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/mny.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
    { icon: "assets/icons/busblue.svg", title: "1,00,000+ Bus Routes", description: "Providing unmatched options to cater to all your travel requirements." },
];

const ChooseUs = () => {
    return (
        <section className="mt-100 whyus--sec">
            <div className="container">
                <div className="whyus--main">
                    <div className="whyus--div">
                        <div className="titlediv text-center mb-3 mb-lg-5">
                            <h4>why choose us?</h4>
                            <p>Choose us for hassle-free online bus bookings with a seamless experience and unbeatable convenience.</p>
                        </div>
                        <div className="whyus--content mt-3">
                            <div className="row justify-content-center">
                                {whyUsData.map((item, index) => (
                                    <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                                        <div className="whyus_card shadow-hover border-hover-none text-center card p-4">
                                            <div className="whyus--icon w-fitcontent m-auto p-4">
                                                <img src={item.icon} alt="" width="55px" className="img-fluid" />
                                            </div>
                                            <div className="whyus--content mt-3">
                                                <h5 className="fw-bold">{item.title}</h5>
                                                <p className="fw-medium">{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChooseUs;
